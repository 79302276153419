import { useEffect, useState } from 'react';
import './App.css';
let Telegram = window.Telegram.WebApp

function App() {
  const [firstName, setFirstName] = useState("Firstname")
  const [lastName, setLastName] = useState("Lastname")

  useEffect(() => {
    setFirstName(`${Telegram?.initDataUnsafe?.user?.first_name}`)
    setLastName(`${Telegram?.initDataUnsafe?.user?.last_name}`)
    Telegram.ready();
  }, [])


  const onClose = () => {
    // Telegram.close()
    console.log(Telegram?.initDataUnsafe?.user);

  }




  return (
    <div className="App">
      <div className='header_block'>
        <div className='avatar_img'></div>
        <div className='user_name'>
          <p>
            {(firstName + lastName).length > 27
              ? `${(firstName + lastName).slice(0, 20)}...`
              : firstName + lastName}
          </p>
        </div>
      </div>
      <div className='content_block'>
        <div className='ballance_block'>
          <p>Баланс</p>
          <p>0.0000</p>
          <button className='nft_btn'>NFT</button>
        </div>
      </div>
      <div className='farm_block'>
        <button className='farm_btn' onClick={onClose}>Фарм</button>
      </div>
      <div className='nft_collection_block'>
        <div className='nft_item'></div>
        <div className='nft_item'></div>
        <div className='nft_item'></div>
        <div className='nft_item'></div>
        <div className='nft_item'></div>
      </div>
    </div>
  );
}

export default App;
